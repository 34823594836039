<template>
    <van-pull-refresh v-model="isRefresh" @refresh="onRefresh">
        <div id="news-list" style="min-height: 100vh;" ref="listView">
            <router-link class="news-item" v-for="(news, index) in dataList" :key="index" :to="'/news/detail/' + news.newsid">
                <router-link class="news-category" v-text="news.category" :to="'/news/list?CatID='+news.catid"></router-link>
                <h3 class="news-title" v-text="news.title"></h3>
                <div class="tags">
                    <router-link :to="'/news/list?keyword='+tag" class="tag" v-for="(tag, i) in news.tag.split(/\||,|，/).filter(d=>!!d)" :key="i" v-text="tag"></router-link>
                </div>
                <div class="doctor-info">
                    <span class="avatar man"></span>
                    <div class="c-info">
                        <span class="doctor-name" v-text="news.creator"></span>
                        <span class="create-time" v-text="news.createdtime"></span>
                    </div>
                    <span class="r-icon"></span>
                </div>
            </router-link>
            <div class="load-more" v-if="isLoadMore">
				<div class="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
				<span class="text">正在加载...</span>
			</div>
			<div class="load-finished" v-if="isLoadFinished">已全部加載</div>
        </div>
        <loading v-if="loading"/>
    </van-pull-refresh>
</template>

<script>
    import Loading from '@/layouts/Loading.vue'
    import news_list from '@/views/mixins/news_list'
	export default {
        mixins: [
            news_list,
        ],
        components: {
            Loading,
        },
        data: function(){
            return {
                pageNo: 1,
                pageSize: 10,
                dataList: [],
				loading: true,
				isRefresh: false,
				isLoadMore: false,
                isLoadFinished: false,
				maxPage: 0,
				keyword: undefined,
				categoryid: undefined,
				clientId: this.$store.getters.clientId,
            }
        },
        mounted: function(){
            var hash = this.$route.hash
            hash = hash.replace('#', '')
            var list = hash.split('&')
            var key = list[0].split('=')[0]
            var value = list[0].split('=')[1] / 1
            if(key == 'page') {
                if(! isNaN(value)) {
                    this.pageNo = parseInt(value) < 1 ? 1 : parseInt(value)
                }
            }
            this.keyword = this.$route.query.keyword || undefined
            this.categoryid = this.$route.query.CatID || undefined
            this.onRefresh()
        },
        methods: {
            onRefresh: function(){
				this.loading = true
                this.loadData({
					clientId: this.clientId,
                    pageNo: this.pageNo,
                    pageSize: this.pageSize,
                    keyword: this.keyword || undefined,
                    catid: this.categoryid || undefined
				}, (data, error)=>{
					this.loading = false
					this.isRefresh = false
					if(data) {
						this.dataList = (data && data.list) || []
						var total = (data && data.total) || 0
						if(total > 0) {
							this.maxPage = Math.floor((total + this.pageSize - 1)/this.pageSize)
							if(this.pageNo >= this.maxPage) {
								this.isLoadFinished = true
							}
						} else {
							if(!error) {
								this.isLoadFinished = true
							}
						}
					} else {
						this._toast.warning(this, error || '加載失敗!')
						this.$router.push('/news/list')
					}
                })
            },
            handleScroll (event) {
				if(this.$refs.listView.clientHeight < window.innerHeight) return
				if((window.innerHeight + window.pageYOffset) > this.$refs.listView.clientHeight) {
					// 加载更多
					if(!this.loading && !this.isLoadMore && this.pageNo < this.maxPage) {
						this.isLoadMore = true
						this.loading = true
						this.loadData({
							clientId: this.clientId,
							pageNo: this.pageNo,
							pageSize: this.pageSize,
							keyword: this.keyword || undefined,
							catid: this.categoryid || undefined
						}, (data, error)=>{
							this.loading = false
							this.isLoadMore = false
							this.dataList = this.dataList.concat((data && data.list) || [])
							if(!error) {
								this.pageNo = this.pageNo + 1
								var total = (data && data.total) || 0
								if(total > 0) {
									var maxPage = Math.floor((total + this.pageSize - 1)/this.pageSize)
									if(this.pageNo >= maxPage) {
										this.isLoadFinished = true
									}
								}
							}
						})
					}
				}
			},
		},
		created () {
			window.addEventListener('scroll', this.handleScroll);
		},
		destroyed () {
			window.removeEventListener('scroll', this.handleScroll);
		},
		watch: {
			'$route.hash': function(v){
				var hash = this.$route.hash
				hash = hash.replace('#', '')
				var list = hash.split('&')
				var key = list[0].split('=')[0]
				var value = list[0].split('=')[1] / 1
				this.pageNo = 1
				if(key == 'page') {
					if(! isNaN(value)) {
						this.pageNo = parseInt(value) < 1 ? 1 : parseInt(value)
					}
				}
				this.keyword = this.$route.query.keyword || undefined
				this.categoryid = this.$route.query.CatID || undefined
				this.onRefresh()
			},
            '$route.query.keyword': function(keyword){
                if(this.loading) return
                this.pageNo = 1
                this.dataList = []
                this.visible = false
                this.keyword = keyword
                this.categoryid = null
				this.breadcrumb = keyword ? ('搜索：'+keyword) : ''
				this.loading = true
                this.onRefresh()
            },
            '$route.query.CatID': function(categoryid){
                if(this.loading) return
                this.breadcrumb = ''
                this.pageNo = 1
                this.dataList = []
                this.visible = false
				this.categoryid = categoryid
				this.loading = true
                this.onRefresh()
            },
            '$props.kk': function(params){
                if(! params) return
                this.breadcrumb = ''
                if(this.loading) return
                this.categoryid = params.categoryid || undefined
                this.keyword = params.keyword || undefined
                this.pageNo = 1
                this.dataList = []
                this.visible = false
                this.onRefresh()
            },
        }
    }
</script>

<style lang="scss" scoped>

    #news-list .news-item {
        display: flex;
        flex-direction: column;
        position: relative;
        margin: 30px 24px 8px 24px;
        border-radius: 16px;
        background-color: #EFF4F9;
    }

    #news-list .news-item .news-category {
        position: absolute;
        left: 32px;
        top: -6px;
        height: 41px;
        padding-left: 21px;
        padding-right: 21px;
        line-height: 41px;
        font-size: 28px;
        color: #fff;
        font-weight: bold;
        background-image: url(../../../assets/imgs/mobile/img_ban@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    #news-list .news-item .news-title {
        font-size: 34px;
        color: #231F20;
        font-weight: bold;
        text-overflow: ellipsis;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        margin: 67px 35px 19px 33px;
    }

    #news-list .news-item .tags {
        display: flex;
        flex-direction: row;
        margin-left: 32px;
    }

    #news-list .news-item .tags > .tag {
        height: 36px;
        line-height: 36px;
        border-radius: 8px;
        background-color: #fff;
        color: #36C4D0;
        font-size: 24px;
        padding: 0px 10px;
        margin-right: 20px;
        cursor: pointer;
    }

    #news-list .news-item .doctor-info {
        display: flex;
        flex-direction: row;
        margin: 30px 34px 20px 32px;
        position: relative;
        justify-content: space-between;
        align-items: center;
    }

    #news-list .news-item .doctor-info > .avatar {
        width: 56px;
        height: 56px;
        background-image: url(../../../assets/imgs/mobile/img_nanysy@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin-right: 15px;
        position: absolute;
        left: 0px;
        top: 11px;
    }

    #news-list .news-item .doctor-info > .c-info {
        display: flex;
        flex-direction: column;
        margin-left: 71px;
    }

    #news-list .news-item .r-icon {
        width: 93px;
        height: 79px;
        background-image: url(../../../assets/imgs/mobile/img_shu@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        align-self: flex-end;
    }

    #news-list .news-item .doctor-info > .c-info > .doctor-name {
        color: #231F20;
        font-size: 24px;
        font-weight: bold;
        line-height: 23px;
    }

    #news-list .news-item .doctor-info > .c-info > .create-time {
        line-height: 19px;
        color: #969696;
        font-size: 24px;
        font-weight: 400;
        margin-top: 15px;
    }

	.load-finished {
		height: 44px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		position: relative;
		font-size: 24px;
		font-weight: 400;
		color: #99999991;
	}

	.load-finished::before {
		content: '';
		width: 120px;
		height: 1px;
		background-color: #99999991;
		right: -130px;
		margin-right: 20px;
		top: 50%;
		transform: translateY(-50%);
	}

	.load-finished::after {
		content: '';
		width: 120px;
		height: 1px;
		background-color: #99999991;
		left: -130px;
		top: 50%;
		margin-left: 20px;
		transform: translateY(-50%);
	}

	// loadmore
	.load-more {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	.load-more > .text {
		margin-left: 24px;
	}


	.lds-default {
		display: inline-block;
		position: relative;
		width: 80px;
		height: 80px;
	}
	.lds-default div {
		position: absolute;
		width: 6px;
		height: 6px;
		background: #36C4D0;
		border-radius: 50%;
		animation: lds-default 1.2s linear infinite;
	}
	.lds-default div:nth-child(1) {
		animation-delay: 0s;
		top: 37px;
		left: 66px;
	}
	.lds-default div:nth-child(2) {
		animation-delay: -0.1s;
		top: 22px;
		left: 62px;
	}
	.lds-default div:nth-child(3) {
		animation-delay: -0.2s;
		top: 11px;
		left: 52px;
	}
	.lds-default div:nth-child(4) {
		animation-delay: -0.3s;
		top: 7px;
		left: 37px;
	}
	.lds-default div:nth-child(5) {
		animation-delay: -0.4s;
		top: 11px;
		left: 22px;
	}
	.lds-default div:nth-child(6) {
		animation-delay: -0.5s;
		top: 22px;
		left: 11px;
	}
	.lds-default div:nth-child(7) {
		animation-delay: -0.6s;
		top: 37px;
		left: 7px;
	}
	.lds-default div:nth-child(8) {
		animation-delay: -0.7s;
		top: 52px;
		left: 11px;
	}
	.lds-default div:nth-child(9) {
		animation-delay: -0.8s;
		top: 62px;
		left: 22px;
	}
	.lds-default div:nth-child(10) {
		animation-delay: -0.9s;
		top: 66px;
		left: 37px;
	}
	.lds-default div:nth-child(11) {
		animation-delay: -1s;
		top: 62px;
		left: 52px;
	}
	.lds-default div:nth-child(12) {
		animation-delay: -1.1s;
		top: 52px;
		left: 62px;
	}
	@keyframes lds-default {
		0%, 20%, 80%, 100% {
			transform: scale(1);
		}
		50% {
			transform: scale(1.5);
		}
	}
</style>
 